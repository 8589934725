<template>
  <div class="trivia">
    <transition mode="out-in" name="main">
      <TriviaIntro @start="state=1" v-if="state===0"/>
      <TriviaDiceDrop @next="startOnCategory" v-else-if="state===1"/>
      <TriviaQuestions
          v-else-if="state===2"
          :category="category"
          :key="category"
          @next="questionsFinished"
      />
      <TriviaCongratulations @next="state=4" v-else-if="state===3"/>
      <TriviaFinished @play-again="playAgain" v-else-if="state===4"/>
    </transition>
    <transition mode="out-in" name="main">
      <TriviaEllipse v-if="state!==2" />
      <div v-else />
    </transition>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, provide} from 'vue';
import TriviaIntro from "@/components/Trivia/TriviaIntro.vue";
import TriviaDiceDrop from "@/components/Trivia/TriviaDiceDrop.vue";
import TriviaQuestions from "@/components/Trivia/TriviaQuestions.vue";
import TriviaCongratulations from "@/components/Trivia/TriviaCongratulations.vue";
import TriviaFinished from "@/components/Trivia/TriviaFinished.vue";
import TriviaEllipse from "@/components/Trivia/TriviaEllipse.vue";

const state = ref(0);

const category = ref(-1);

const startOnCategory = (cat) => {
  console.log(category);
  state.value = 2;
  category.value = cat;
};

const data = ref([
  {
    //Fashion
    questions: [
      {
        question: "Έχει κυκλοφορήσει συσκευή IQOS σχεδιασμένη από διάσημο σχεδιαστή μόδας;",
        answers: [
          "Ναι",
          "Όχι"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 0,
      },
      {
        question: "Ποιος σχεδιαστής θα θέλατε να σχεδιάσει την επόμενη συσκευή IQOS;",
        freetext: true,
        questionId: 1,
      }
    ]
  },
  {
    //Taste
    questions: [
      {
        question: "Πως λέγονται οι ράβδοι καπνού που μπορείς να χρησιμοποιήσεις με το IQOS ILUMA;",
        answers: [
          "TEREA",
          "HEETS",
          "FITS",
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 2,
      },
      {
        question: "Τι γεύση έχει η απόλαυση για σένα;",
        freetext: true,
        value: "",
        questionId: 3,
      }
    ]
  },
  {
    //General
    questions: [
      {
        question: "Πόσοι Έλληνες καταναλωτές επιλέγουν IQOS;",
        answers: [
          "Περισσότεροι από 500.000",
          "300.000",
          "100.000"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 4,
      },
      // {
      //   question: "Τι είναι το contouring;",
      //   answers: [
      //     "Είδος γυμναστικής",
      //     "Τεχνική μανικιούρ",
      //     "Τεχνική μακιγιάζ",
      //   ],
      //   correctAnswer: 2,
      //   activeAnswer: -1,
      //   questionId: 9,
      // },
      // {
      //   question: "Πόσα χρόνια πραγματοποιείται το festival «Salt Mountain»;",
      //   answers: [
      //     "3",
      //     "10",
      //     "6"
      //   ],
      //   correctAnswer: 2,
      //   activeAnswer: -1,
      //   questionId: 5,
      // }
    ]
  },
  {
    //Music
    questions: [
      {
        question: "Σε ποια μουσική συναυλία θα ήθελες να συναντήσεις το IQOS;",
        freetext: true,
        value: "",
        questionId: 6,
      },
      {
        question: "Τι σημαίνει pop μουσική;",
        answers: [
          "Δημοφιλής",
          "Λαϊκή",
          "Έντεχνη",
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 7,
      },
      {
        question: "Ποιο συγκρότημα έχει πουλήσει τους περισσότερους δίσκους παγκοσμίως μέχρι και σήμερα;",
        answers: [
          "The Beatles",
          "Aerosmith",
          "Police"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 8,
      }
    ]
  },
]);

const questionsFinished = () => {
  state.value = 3;

  setTimeout(() => {
    if (state.value === 3) {
      state.value = 4;
    }
  }, 5000);
};

const playAgain = () => {
  data.value.forEach((category) => {
    category.questions.forEach((question) => {
      if (question.freetext) {
        question.value = "";
      } else {
        question.activeAnswer = -1;
      }
    });
  });

  state.value = 0;
};

provide('trivia', {
  data,
  category
});
</script>

<style scoped lang="scss">
.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}
</style>
